import { Component, inject, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationComponent } from 'src/app/auth/components/registration/registration.component';

@Component({
  selector: 'app-valores-v1',
  templateUrl: './valores-v1.component.html',
  styleUrls: ['./valores-v1.component.css']
})
export class ValoresV1Component{
  modalService = inject(NgbModal)

  @Input() pricingData: Array<{
    melhorOpcao?: string,
    titulo: string,
    valor: number,
    ciclo: string,
    isDesc: boolean,
    desconto: string,
    list?: Array<[]>,
    btn?: string,
  }>;



  abrirModalRegistroNovoAssinante(opcao: string) {
    let modalRef = this.modalService.open(
      RegistrationComponent,
      {
        modalDialogClass: 'modal-dialog',
        size: 'md',
        scrollable: true,
        centered: true,
        backdrop: 'static',
        keyboard: false,
      }
    )

    modalRef.componentInstance.planoSelecionado = opcao;
  }
}
