import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from 'src/app/_core/services/loader.service';
import { SecurityUtil } from 'src/app/_core/utils/security.util';
import { LoginModel } from 'src/app/auth/models/login.model';
import { ResultV1Model } from 'src/app/shared/models/result-v1.model';
import Swal from 'sweetalert2';
import { TratamentoErrosHttpErrorResponseService } from 'src/app/shared/services/tratamento-erros-http-error-response.service';
import { LoginService } from './login.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { AccountV1Model } from '../models/account-v1.model';
import { LoginAccessV1Model } from '../models/login-access-v1.model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})

export class LoginFuncoesCompartilhadasV1Service {
  //variáveis de ambiente
  static isRedefinirSenhaSub = new BehaviorSubject<boolean>(false);
  private isRedefinirSenha: boolean = false;

  //variáveis identificacao de dispositivo
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktop: boolean = false;


  constructor(
    public modalAtivo: NgbActiveModal,
    private readonly loginService: LoginService,
    private readonly loaderService: LoaderService,
    public route: Router,
  ) {
    //Setando o tipo de dispositivo
    this.isMobile = window.innerWidth < 768;
    this.isTablet = (window.innerWidth >= 768 && window.innerWidth < 992);
    this.isDesktop = window.innerWidth >= 992;

    LoginFuncoesCompartilhadasV1Service.isRedefinirSenhaSub.subscribe(
      op => this.isRedefinirSenha = op
    )
  }

  fecharModal() {
    LoginFuncoesCompartilhadasV1Service.isRedefinirSenhaSub.next(false);
    this.modalAtivo.close();
  }

  /**
   * Função utilizada para verificar se há token de login no localStorage
   */
  public verificarSeUsuarioEstaLogado() {
    if (SecurityUtil.hasToken()) {
      /**Se tiver o token, é feito uma validação do token para verificar se pode redirecionar o usuário para o painel da conversão */
      this.loginService.refreshtoken().subscribe({
        next: resultado => {
          if (resultado.success) {
            /**
             * Se o token for validado, o novo token retornado será setado no localStorage
             * bem como o userAccount (AccountV1Model).
             */
            SecurityUtil.setToken(resultado.data.token)
            this.getUserAccById();
            this.route.navigate([`/assinantes/conversor`]);
          } else {
            /**
             * Caso ocorra algum erro durante a validação do token, será feito a limpa
             * do localStorage e o usuário terá que fazer o login na plataforma, informando os dados
             */
            SecurityUtil.clear();
            this.loaderService.stopLoader();
          }
        },
        error: () => {
          /**
           * Caso ocorra algum erro durante a validação do token, será feito a limpa
           * do localStorage e o usuário terá que fazer o login na plataforma, informando os dados
           */
          SecurityUtil.clear();
          this.loaderService.stopLoader();
        }
      }
      );
    }
  }

  /**
   * Função utilizada para fazer login na plataforma
   * @param dadosLogin Os dados para acesso, sendo eles email e senha
   * @param isFinalizarCadastro Opcional, caso seja true, ao invés de ser direcionado para o painelConversor,
   * o usuário será direcionado para o componente Contratar
   */
  public async fazerLogin(dadosLogin: LoginModel, isFinalizarCadastro: boolean, routeUrl: string = '') {
    try {
      const resultado = await lastValueFrom(this.loginService.autenticar(dadosLogin));
      if (resultado.success) {
        const account: LoginAccessV1Model = resultado.data.account;

        !account.isAssinatura ? account.isAssinatura = false : null;
        !account.isManager ? account.isManager = false : null;
        !account.isParceiro ? account.isParceiro = false : null;
        !account.isTeste ? account.isTeste = false : null;
        !account.isReteste ? account.isReteste = false : null;

        try {
          /**Setando o token e o account no localStorage */
          SecurityUtil.set(
            account,
            resultado.data.token,
          );

          /**Setando o account do usuário a logar */
          this.getUserAccById();

          if (!routeUrl) {
            /**Avaliando qual processo chamou o componente de login */
            if (isFinalizarCadastro!) {
              /**
               * Se for o processo de finalização de cadastro, 
               * o usuário é redirecionado para assinantes/contratar
               * para que possa finalizar a contratação, 
               * com isto, a variável routeUrl recebe a rota para contratação
               */
              routeUrl = '/assinantes/contratar';
            } else {
              /**
               * Se não for, o usuário será redirecionado para o painel do conversor, 
               * com isto, a variável routeUrl recebe a rota para o painel
               */
              routeUrl = '/assinantes/conversor';
            }
          }

          /** Navegando para rota definida */
          this.route.navigate([`${routeUrl}`]);
        } catch (error) {
          //em caso de qualquer erro durante a tentativa de login, será feito uma limpa no localStorage, onde é setado o ofx-account/ofx-token
          localStorage.clear();
        }

      } else {
        throw new Error(`Erro ao fazer login: ${JSON.stringify(resultado)}`);
      };
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        /**Tratamento dos erros que é retornado pela API */
        if (error.error.error == 'expiredtest') {
          /**Se o error == 'expiredtest' indica que o período de teste expirou/as conversões finalizaram e o usuário poderá contratar o plano */
          this.alertaContratar(error.error, dadosLogin);
        } else if (error.error.error == 'expiredsignature') {
          /**Se o error == 'expiredsignature' indica que o período da assinatura expirou e não houve a renovação */
          this.alertaRenovarContratacao(error.error, dadosLogin);
        } else if (error.error.error == 'notaccesskey') {
          /**Se o error == 'notaccesskey' indica que a senha está incorreta */
          this.alertaResetarSenha(error.error);
        } else if (error.error.error == 'notfound') {
          /**Se o error == 'notfound' indica que o usuário não foi localizado */
          this.alertaEmailNaoLocalizado(error.error);
        } else if (error.error.error == 'unverified') {
          /**Se o error == 'unverified' indica que o email não foi verificado */
          this.alertaEmailNaoVerificado(error.error, dadosLogin);
        } else {
          TratamentoErrosHttpErrorResponseService.tratarErro(error);
        }
      } else if (error instanceof Error) {
        if (error.message.includes('Erro ao fazer login')) {
          const resultado = JSON.parse(error.message.split(':')[1]);
          //tratamento de erro, caso o resulto.success == false
          Swal.fire(
            resultado.titulo,
            resultado.message,
            'error'
          );
        } else {
          console.log('error: ', error)
        }
      } else {
        console.log('error: ', error)
      }
      this.loaderService.stopLoader();
    }
  }

  alertaCodigoInvalido() {
    throw new Error(
      'O código de verificação digitado está incorreto, favor verificar o código digitado.'
    );
  }

  // Private Methods
  /**Caso o usuário já tenha utilizado o teste e o reteste e a data de avaliação tenha expirado */
  private alertaContratar(err: ResultV1Model, dadosLogin: LoginModel) {
    Swal.fire({
      title: err.titulo,
      text: err.message,
      showConfirmButton: true,
      confirmButtonText: 'Contratar'
    }).then(async () => {
      /**Ao clicar na opção contratar */
      await this.loginService.getContaByIdUserNaoAutenticado(err.data.codigo).subscribe({
        next: resultado => {
          /**Será feito um get do user, com o idAccount que é retornado pelo erro no login */
          localStorage.setItem('userAccount', btoa(JSON.stringify(resultado.data)));

          //Os dados para logar na plataforma, após a renovação da assinatura é setado no armazenamento de sessão
          sessionStorage.setItem('dadosLogin', btoa(JSON.stringify(dadosLogin)));

          /**O usuário então será redirecionado para a página de contratação, sem fazer login na plataforma */
          this.route.navigate(['/nao-assinantes/contratar']);
        },
        /**Em caso de erros, será feito o tratamento */
        error: err => TratamentoErrosHttpErrorResponseService.tratarErro(err)
      });
    });
  }

  /**Função utilizada quando, ao logar, não é localizado no banco de dados o email digitado pelo usuário */
  private alertaEmailNaoLocalizado(err: ResultV1Model) {
    Swal.fire({
      title: err.titulo,
      text: err.message,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Criar conta'
    }).then(
      (result) => {
        /**Se o usuário optar por 'Criar conta' */
        if (result.isConfirmed) {
          //Será redierecionado para o componente AutoRegistro
          this.route.navigate(['/auth/registro']);
        }
      }
    );
  }

  /**
   * Exibe um alerta quando o e-mail do usuário não está verificado.
   * @param err - O objeto de erro contendo o título e a mensagem a ser exibida.
   */
  private alertaEmailNaoVerificado(err: ResultV1Model, dadosLogin: LoginModel) {
    // Usando Swal para exibir um alerta de erro
    Swal.fire(
      err.titulo, // Título do alerta
      err.message, // Mensagem do alerta
      'error' // Tipo de alerta
    ).then(() => {
      if (!localStorage.getItem('dadosLoginCriarConta')) {
        // Se os dados para login do usuário não estiver setado no localStorage, será setado
        localStorage.setItem('dadosLoginCriarConta', btoa(JSON.stringify(dadosLogin)));
      }

      // Redireciona o usuário para a página de confirmação do email
      this.route.navigate(['/auth/confirmar-conta']);
    });
  }

  /**Função utilizada quando, ao logar, é retornado que a assinatura expirou */
  private alertaRenovarContratacao(err: ResultV1Model, dadosLogin: LoginModel) {
    Swal.fire({
      title: err.titulo,
      text: err.message,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: 'Renovar contratação'
    }).then(async result => {
      /**Se o usuário optar por 'Renovar contratação' */
      if (result.isConfirmed) {
        /**Será feito um get no account do usuário, com o idAccount retornado pelo erro no login */
        await this.loginService.getContaByIdUserNaoAutenticado(err.data._idAccount).subscribe({
          next: resultado => {
            //O account então é setado no armazenamento local (localStorage)
            localStorage.setItem('userAccount', btoa(JSON.stringify(resultado.data)));

            //Os dados para logar na plataforma, após a renovação da assinatura é setado no armazenamento de sessão
            sessionStorage.setItem('dadosLogin', btoa(JSON.stringify(dadosLogin)));

            //O usuário é redirecionado para o componente de contratação, sem fazer login na plataforma
            this.route.navigate(['/nao-assinantes/contratar']);
          },
          //Em caso de erros, será feito o tratamento do erro
          error: err => TratamentoErrosHttpErrorResponseService.tratarErro(err)
        });
      }
    });
  }

  private alertaResetarSenha(err: ResultV1Model) {
    Swal.fire({
      title: err.titulo,
      text: err.message,
    });
  }

  public redefinirSenha() {
    LoginFuncoesCompartilhadasV1Service.isRedefinirSenhaSub.next(true);
  }

  /**Função utilizada para setar o Account do usuário no localStorage e emitir o valor no subject userLogado */
  private async getUserAccById() {
    await this.loginService.getContaById().subscribe({
      next: resultado => {
        localStorage.setItem('userAccount', btoa(JSON.stringify(resultado.data)))
        LoginFuncoesCompartilhadasV1Service.userLogado.next(resultado.data)
      }
    })
  }

  //inicio subjects
  public static userLogado = new BehaviorSubject<AccountV1Model | undefined>(undefined);

  public static reiniciarUserLogado() {
    LoginFuncoesCompartilhadasV1Service.userLogado = new BehaviorSubject<AccountV1Model | undefined>(undefined);
  }
  //fim subjects
}
