<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" [routerLink]="linkRedirecionamento">
        <img src="assets/imgs/extratoofx-color.png" height="35" alt="" class="l-light logo-light-mode">
      </a>
    </div>
    <!--end login button-->



    <!--Login button Start-->
    @if(Settingicon == true){
    <ul class="navigation-menu buy-button list-inline mb-0">
      <li class="has-submenu">
        <a href="javascript:void(0)" (click)="onMenuClick($event)">
          <div class="btn btn-icon btn-pills btn-soft-primary" id="settingbtn">
            <i-feather name="settings" class="fea icon-sm"></i-feather>
          </div>
        </a>
        @if(Menuoption === 'conversor'){
        <ul class="submenu">
          <li>
            <ul>
              <li><a class="nav-link-ref" routerLink="/assinantes/meu-perfil">Meu Perfil</a></li>
              <li><a class="nav-link-ref" routerLink="/assinantes/financeiro">Financeiro</a></li>
              <li><a class="nav-link-ref" routerLink="/home">Sair</a></li>
            </ul>
          </li>
        </ul>
        }
      </li>
    </ul>
    }
    <!--Login button End-->

    <!-- Navigation Menu-->
    <div id="menu-navegacao">
      <ul class="navigation-menu">
        <li><a routerLink="/assinantes/conversor" class="nav-link-ref">Home</a></li>
        @if(Menuoption == 'conversor'){
        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Converter</a>
          <span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <ul>
                <li><a class="nav-link-ref" routerLink="/assinantes/conversor/realizar-conversao/pdf-para-ofx">PDF para OFX</a></li>
                <li><a class="nav-link-ref" routerLink="/assinantes/conversor/realizar-conversao/excel-para-ofx">Excel para OFX</a></li>
                <li><a class="nav-link-ref" routerLink="/assinantes/conversor/realizar-conversao/csv-para-ofx">CSV para OFX</a></li>
              </ul>
            </li>
          </ul>
        </li>
        }
      </ul>
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->