import { Component, Input, OnChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { LoginFuncoesCompartilhadasV1Service } from 'src/app/auth/services/login-funcoes-compartilhadas-v1.service';
import { SecurityUtil } from 'src/app/_core/utils/security.util';
import { RegistrationComponent } from 'src/app/auth/components/registration/registration.component';
import { LoginService } from 'src/app/auth/services/login.service';
import { LoginAccessV1Model } from 'src/app/auth/models/login-access-v1.model';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
})
export class NavBarComponent implements OnChanges {

  /** Variaveis de entrada */
  @Input() isCondensed = false;
  @Input() currentSection = 'home';
  @Input() Menuoption = 'home';
  @Input() Settingicon = true;
  @Input() linkRedirecionamento = '';

  //variáveis do usuário logado
  dadosAccount: LoginAccessV1Model | undefined;
  isTesteOrReteste: boolean = false;
  isAssinatura: boolean = false;
  isManager: boolean = false;
  isParceiro: boolean = false;

  //variáveis identificacao de dispositivo
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktop: boolean = false;

  constructor(
    private modalService: NgbModal,
    private route: Router,
    private readonly loginService: LoginService
  ) {
    this.isMobile = window.innerWidth < 768;
    this.isTablet = (window.innerWidth >= 768 && window.innerWidth < 992);
    this.isDesktop = window.innerWidth >= 992;
  }

  ngOnChanges(): void {
    /**Avaliando a rota ativada */
    if (!this.route.isActive('home', true)
      && !this.route.isActive('nao-assinantes/contratar', true)
      && !this.route.isActive('nao-assinantes/tabela-usuarios-avaliacao', true)
      && !this.route.isActive('nao-assinantes/parceiro', true)
    ) {
      /**
       * Se a rota que estiver ativa for diferente de (rotas que são permitido o acesso sem autenticar na plataforma):
       * - 'home';
       * - nao-assinantes/contratar;
       * - nao-assinantes/parceiro;
       * - nao-assinantes/tabela-usuarios-avaliacao;
       * será avaliado se há token no localStorage
       */
      if (!SecurityUtil.hasToken()) {
        /**
         * Neste ponto, se não houver token gravado no localStorage, significa que não foi possível trazer os dados
         * do userLogado, no get, na API. Com isto, é redirecionado à tela inicial 'home', 
         * deslogando da ferramenta.
         */
        this.route.navigate(['/home']);
      }
    }

    if (localStorage.getItem('ofx-account')) {
      this.dadosAccount = SecurityUtil.getAccount();
    }

    if (this.dadosAccount!) {
      this.isAssinatura = this.dadosAccount?.isAssinatura;
      this.isManager = this.dadosAccount?.isManager;
      this.isTesteOrReteste = (this.dadosAccount?.isTeste == true || this.dadosAccount?.isReteste == true) ? true : false;
      this.isParceiro = this.dadosAccount?.isParceiro;
    }
  }

  /**
   * Toggle menu
   */
  toggleMenu() {
    this.isCondensed = !this.isCondensed;
    if (this.isCondensed) {
      document.getElementById('navigation').style.display = 'block';
    } else {
      document.getElementById('navigation').style.display = 'none';
    }
  }

  /**
   * Menu clicked show the submenu
   */
  onMenuClick(event) {
    event.preventDefault();
    const nextEl = event.target.nextSibling.nextSibling;
    if (nextEl && !nextEl.classList?.contains("open")) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove("open");
      }
      nextEl.classList.add("open");
    } else if (nextEl) {
      nextEl.classList.remove("open");
    }
    return false;
  }

  /**
   * Window scroll method
   */
  windowScroll() {
    if (this.isDesktop!) {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById('topnav').classList.add('nav-sticky');
      } else {
        document.getElementById('topnav').classList.remove('nav-sticky');
      }

      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        document.getElementById('back-to-top')!
          ? document.getElementById('back-to-top').style.display = 'inline'
          : null;
      } else {
        document.getElementById('back-to-top')!
          ? document.getElementById('back-to-top').style.display = 'none'
          : null;
      }
    }
  }

  /**
   * Desloga o usuário navegando para a página de login
   * e limpando os dados armazenados.
   */
  fazerLogout() {
    // Navegar para a página de login
    this.route.navigate(['/auth/login']);

    // Limpar todos os dados salvos no localStorage
    SecurityUtil.clear();

    // Limpar todos os dados salvos no sessionStorage
    sessionStorage.clear();
  }

  /**
   * Recupera a conta do usuário pelo ID e atualiza o armazenamento local e o estado do usuário.
   */
  async getUserAccById() {
    // Chama o serviço de login para obter a conta por ID
    this.loginService.getContaById().subscribe({
      next: resultado => {
        // Atualiza o estado do usuário usando funções compartilhadas de login
        LoginFuncoesCompartilhadasV1Service.userLogado.next(resultado.data);

        // Armazena a conta do usuário no armazenamento local em formato base64
        localStorage.setItem('userAccount', btoa(JSON.stringify(resultado.data)));
      }
    });
  }
}
