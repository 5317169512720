@if(!hideFooter){
@if(footerVariant === 'home'){
<footer class="footer container-fluid container-lg p-5 text-center rounded-top">
  <!-- <a href="javascript:void(0)" class="logo-footer position-absolute bottom-0" style="left: 10px;">
    <img src="assets/imgs/extratoofx-white.png" height="40" alt="logo da extrato ofxs">
  </a> -->
  <h2 class="text-light">Converta até 50 arquivos durante 24 horas</h2>
  <span>Experimente a praticidade que o nosso SaaS oferece.</span>
  <div>
    <button type="button" class="btn btn-dark-saas mt-3" (click)="abriModalQueroTestar()">
      Testar agora
    </button>
  </div>
</footer>
}@else if(footerVariant === 'conversor'){
<footer class="footer" [ngClass]="{'position-none' : isFooterNotFixed}">
  <div class="footer-py-20 footer-bar">
    <div class="container text-center">
      <div class="row">
        <div class="col d-flex justify-content-between align-items-center">
          <span>
            ® {{year}} - 
            <b>extratoOFX - 
              <a href="https://epigrafo.com" target="_blank" class="link cursor-pointer" style="color: #adb5bd;">
                Epigrafo.
              </a>
            </b>
          </span>
          <ul class="list-group list-group-horizontal list-group-flush">
            <!-- <li class="list-group-item bg-transparent">
              <a class="link text-light cursor-pointer" href="javascript:void(0)">Política de Privacidade</a>
            </li> -->
            <li class="list-group-item bg-transparent">
              <a class="link text-light cursor-pointer" routerLink="/auth/termos-e-condicoes-de-uso" target="_blank" href="javascript:void(0)">
                Termos e condições de uso
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
}
}