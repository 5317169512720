<section class="container mt-5 pt-1">
  <div class="card-content border bg-white">
    <div class="card-header">
      <h5 class="card-title">
        Lista de usuários em avaliação
      </h5>
    </div>
    <div class="card-body">
      @if(listaClientes?.length > 0){
      <div class="row">
        <div class="col-12 col-md">
          <div class="float-start" id="centro-custo-table-length">
            <label class="d-inline-flex align-items-center text-nowrap">Mostrar
              <select name="centro-custo-table_length" [(ngModel)]="limit" (ngModelChange)="alterarQtdItens()"
                aria-controls="centro-custo-table" name="limit"
                class="custom-select custom-select-sm form-control form-control-sm mx-2">
                <option [ngValue]="10">10</option>
                <option [ngValue]="25">25</option>
                <option [ngValue]="50">50</option>
                <option [ngValue]="100">100</option>
              </select> Usuários
            </label>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-hover table-bordered mb-0 bg-secondary">
          <thead>
            <tr class="text-center" align="center">
              <th [width]="'25%'">Nome</th>
              <th [width]="'15%'">Telefone</th>
              <th [width]="'20%'">Email</th>
              <th [width]="'30%'">Periodo Restante</th>
            </tr>
          </thead>
          <tbody>
            @for (cliente of listaClientesPaginada | paginate: {
            itemsPerPage: this.limit,
            currentPage: this.paginaAtual,
            totalItems: this.totalCount,
            }; track i; let i = $index) {
            <tr class="text-center">
              <td>{{cliente.account.nome}}</td>
              <td class="text-nowrap">
                {{cliente.account.celular! ? (cliente.account?.celular | phone) : 'não informado'}}
              </td>
              <td>{{cliente.account.email}}</td>
              <td><small>{{cliente.avaliacao.periodoRestante}}</small></td>
            </tr>
            }
          </tbody>
        </table>
      </div>
      }@else {
      <div class="d-flex bg-white justify-content-center">
        <h5 class='text-muted p-3'>
          Não foi localizado usuários em avaliação, na base de dados
        </h5>
      </div>
      }
    </div>
    @if(listaClientes.length > 0){
    <div class="card-footer">
      <div class="row">
        <div class="col-xxl-5">
          <p class="mt-2 mb-0" id="empresas-table_info" role="status" aria-live="polite">
            {{skip + 1}} à {{(skip + 1) * limit > listaClientes.length ?
                  totalCount : skip + limit}} de {{totalCount}} usuários.
          </p>
        </div>
        <div class="col-xxl-7 mt-1 text-end">
          <pagination-controls [autoHide]="true" previousLabel="Ant" [maxSize]="5" [responsive]="true"
            (pageChange)="definindoSkipPaginate($event)" nextLabel="Prox">
          </pagination-controls>
        </div>
      </div>
    </div>
    }
  </div>
</section>