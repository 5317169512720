<div class="row g-3 justify-content-between" [ngClass]="{'mt-4' : !isMobile}">
  <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
    <div class="title-heading">
      <h2 class="mb-3 lh-1 text-primary text-wrap">
        <strong>
          Converta extratos bancários para OFX sem limites de CNPJ e sem limites de conversão. Prontos para lançamentos contábeis!
        </strong>
      </h2>
      <h5 class="mt-5 text-muted">Teste grátis por 24 horas.</h5>
      <button type="button" class="btn btn-primary" (click)="abriModalQueroTestar()">
        Testar agora
      </button>
    </div>
  </div>
  <div class="col-12 col-md-6">
    <img src="assets/imgs/banners/banner-extrato-ofx.png" alt="" class="img-fluid">
  </div>
</div>
