import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { RegistrationComponent } from 'src/app/auth/components/registration/registration.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

/***
 * Footer Component
 */
export class FooterComponent {
  @Input() footerVariant: string;
  @Input() hideFooter: boolean;
  isDesktop: boolean;
  isFooterNotFixed: boolean;
  
  static isFooterNotFixed = new BehaviorSubject<boolean>(false);

  //Get Year
  year = new Date().getFullYear()

  constructor(
    private modalService: NgbModal,
  ) {
    this.isDesktop = window.innerWidth >= 1024;
    FooterComponent.isFooterNotFixed.subscribe(op => this.isFooterNotFixed = op);
  }

  abriModalQueroTestar() {
    let modalRef = this.modalService.open(
      RegistrationComponent,
      {
        modalDialogClass: 'modal-dialog',
        size: 'md',
        scrollable: true,
        centered: true,
        backdrop: 'static',
        keyboard: false,
      }
    )

    modalRef.componentInstance.planoSelecionado = undefined;
  }
}
