import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { ResultV1Model } from '../models/result-v1.model';

@Injectable({
  providedIn: 'root',
})
export class TratamentoErrosHttpErrorResponseService {
  public static tratarErro(err: HttpErrorResponse) {
    console.log('error', err);
    const erro: ResultV1Model = err.error;
    let mensagem = '';

    if (!erro.message || !erro.titulo || (erro.message! && erro.titulo! && erro.message.includes('Verifique as'))) {
      if (typeof (erro.error) === 'string') {
        /**Caso tenha somente uma mensagem de erro */
        mensagem = erro.error;
      } else if (erro.error?.forEach!) {
        /**Caso tenha um array com as mensagens de erro */
        erro.error.forEach((msg: string) => {
          mensagem = msg + ' ';
        });
      } else {
        mensagem = 'Não foi possível concluir sua solicitação. Tente novamente mais tarde.'
      }
    } else if (erro.message! && erro.titulo!) {
      mensagem = erro.message;
    }

    if (err.status === 404) {
      //Not Found
      mensagem = 'Ocorreu um erro na tentativa de comunicacar com o servidor. Rota para realizar a solicitação não localizada.'
      Swal.fire(
        'Erro na comunicação.',
        mensagem,
        'error'
      );
    } else if (err.status === 500) {
      //Internal Server Error
      mensagem = 'No momento estamos com uma inconsistência no servidor. Tente novamente mais tarde.'
      Swal.fire(
        'Erro no servidor.',
        mensagem,
        'error'
      );
    } else if (err.status === 400) {
      //Bad Request
      Swal.fire(
        erro.titulo,
        mensagem,
        'error'
      )
    } else if (err.status === 401) {
      //Unauthorized
      mensagem = 'Ops, você não tem autorização para concluir esta solicitação.'
      Swal.fire(
        'Acesso não autorizado!',
        mensagem,
        'error'
      );
    } else if (err.status == 0) {
      mensagem = 'No momento estamos com uma inconsistência no servidor. Tente novamente mais tarde.'
      Swal.fire(
        'Erro no servidor.',
        mensagem,
        'error'
      );
    } else {
      if (mensagem!) {
        Swal.fire(
          erro.titulo,
          mensagem,
          'error'
        );
      } else {
        mensagem = 'Ops, algo deu errado durante a requisição. Tente novamente mais tarde.'
        Swal.fire(
          'Algo deu errado!',
          mensagem,
          'error'
        );
      }
    }
  }
}
