import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { LoaderService } from 'src/app/_core/services/loader.service';
import { CpfPipe } from 'src/app/shared/pipe/cpf.pipe';
import { FormReativoService } from 'src/app/shared/services/form-reativo.service';
import { LoginService } from '../../services/login.service';
import { TratamentoErrosHttpErrorResponseService } from 'src/app/shared/services/tratamento-erros-http-error-response.service';
import Swal from 'sweetalert2';
import { NewAccountV1Interface } from '../../models/account-crud-v1-interface';
import { LoginModel } from '../../models/login.model';
import { lastValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  standalone: true,
  imports: [
    FeatherModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
})
export class RegistrationComponent {
  //variáveis de ambiente
  formNovaConta: FormGroup;
  isLiEConcordo: boolean = false;

  //variáveis a serem preenchidas ao chamar o modal
  planoSelecionado?: string;

  constructor(
    public modalAtivo: NgbActiveModal,
    public formReativo: FormReativoService,
    public cpfPipe: CpfPipe,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private loginService: LoginService,
    private router: Router
  ) {
    this.formNovaConta = this.fb.group(this.montarForm(), {
      validators: [this.formReativo.MustMatch('password', 'passwordConfirm')],
    });
  }

  montarForm() {
    return {
      nome: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email
        ])
      ],
      celular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ])
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)
        ])
      ],
      passwordConfirm: [
        '',
        Validators.compose([
          Validators.required
        ])
      ]
    }
  }

  get controls() {
    return this.formNovaConta.controls;
  }

  fecharModalEExcluirPlanoSelecionado() {
    this.modalAtivo.close();
    sessionStorage.removeItem('planoSelecionado');
  }

  /**
   * Função chamada quando o usuário clica no botão de criar conta
   * Verifica se o email já existe no banco de dados.
   * Se o email não existir, cria uma conta com o email e senha informados.
   * Se o email existir, abre um modal para o usuário decidir o que fazer.
   */
  async criarContaEVerificarEmail() {
    const nome: string = this.controls['nome'].value;
    const conta: NewAccountV1Interface = {
      form: {
        opcao: this.planoSelecionado ? this.planoSelecionado : "Não Definido",
        nome: nome,
        email: this.controls['email'].value,
        accesskey: this.controls['password'].value,
        celular: this.controls['celular'].value
      },
    };

    this.criarNovaContaTesteEVerificarEmail(conta);
  }

  /**
   * Função chamada quando o usuário clica no botão de criar conta.
   * @param {NewAccountV1Interface} conta - Contém as informações para criar uma conta.
   */
  async criarNovaContaTesteEVerificarEmail(conta: NewAccountV1Interface) {
    this.loaderService.startLoader(false);
    try {
      const resultado = await lastValueFrom(this.loginService.criarNovaContaAvaliacao(conta));

      if (resultado.success) {
        Swal.fire(
          resultado.titulo,
          `Você receberá um código no email informado (${conta.form.email}) para confirmar o cadastro e então liberar o acesso a plataforma.`,
          'success'
        ).then(() => {
          const dadosLogin = new LoginModel(
            conta.form.email,
            conta.form.accesskey
          );

          // Guarda os dados de login para que o usuário possa fazer login logo após o cadastro.
          localStorage.setItem(
            'dadosLoginCriarConta',
            btoa(JSON.stringify(dadosLogin))
          );

          let rotaNavegacao: string;
          if (this.planoSelecionado) {
            // Se o plano for selecionado, direciona para a página de confirmar conta com o parâmetro de plano.
            rotaNavegacao = '/auth/confirmar-conta/true';
          } else {
            // Caso contrário, direciona para a página de confirmar conta sem o parâmetro de plano.
            rotaNavegacao = '/auth/confirmar-conta'
          }

          // Navega para a página de confirmar conta.
          this.router.navigate([rotaNavegacao]);

          // Fecha o modal de cadastro.
          this.modalAtivo.close();
        });
      } else {
        // Se o email existir, lança um erro.
        throw new Error(
          `Erro ao criar nova conta: ${JSON.stringify(resultado)}`
        );
      }
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        // Se o erro for um erro de http, trata o erro.
        TratamentoErrosHttpErrorResponseService.tratarErro(error);
      } else if (error instanceof Error) {
        // Se o erro for um erro de aplicação, verifica se o erro tem a mensagem de erro de criar conta.
        if (error.message.includes('Erro ao criar nova conta: ')) {
          // Se sim, extrai o resultado do erro e lança um alerta com o título e a mensagem do erro.
          const resultadoError = JSON.parse(error.message.split('Erro ao criar nova conta: ')[1]);

          Swal.fire(
            resultadoError.titulo,
            resultadoError.message,
            'error'
          )
        }
      } else {
        console.log('error')
        console.log(error)
      }
    } finally {
      // Para o loader.
      this.loaderService.stopLoader();
    }
  }
}
