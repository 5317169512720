<div class="modal-content rounded shadow border-0">
  <div class="modal-header">
    <h5 class="modal-title" id="modal-title">{{planoSelecionado! ? 'Contratar' : 'Teste por 24 horas'}}
    </h5>
    <button type="button" class="btn btn-close btn-lg" data-dismiss="modal" aria-label="Close"
      (click)="fecharModalEExcluirPlanoSelecionado()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="content pt-2">
      <form [formGroup]="formNovaConta" class="form">
        <div class="row">
          <div class="col-lg-12">
            <div class="mb-3">
              <label class="form-label">Nome: <span class="text-danger">*</span></label>
              <div class="form-icon position-relative">
                <i-feather name="user" class="fea icon-sm icons"></i-feather>
                <input type="text" class="form-control ps-5" placeholder="Nome" formControlName="nome"
                  [ngClass]="formReativo.definindoClasseValidator(formNovaConta, 'nome')" ngbAutofocus>
                @if(controls['nome'].errors?.['required'] && controls['nome'].dirty){
                <small class="text-danger mt-1">*Campo Obrigatório</small>
                }
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="mb-3">
              <label class="form-label">E-mail: <span class="text-danger">*</span></label>
              <div class="form-icon position-relative">
                <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                <input type="email" class="form-control ps-5" placeholder="E-mail" formControlName="email"
                  [ngClass]="formReativo.definindoClasseValidator(formNovaConta, 'email')">
                @if(controls['email'].dirty){
                @if(controls['email'].errors?.['required']){
                <small class="text-danger mt-1">*Campo Obrigatório</small>
                }@else if(controls['email'].errors?.['email']){
                <small class="text-danger mt-1">*Informe um e-mail válido.</small>
                }
                }
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="mb-3">
              <label class="form-label">Telefone Celular: <span class="text-danger">*</span></label>
              <div class="form-icon position-relative">
                <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                <input type="text" class="form-control ps-5" placeholder="Whatsapp" formControlName="celular"
                  [ngClass]="formReativo.definindoClasseValidator(formNovaConta, 'celular')">
                @if(controls['celular'].dirty){
                @if(controls['celular'].errors?.['required']){
                <small class="text-danger mt-1">*Campo Obrigatório</small>
                }@else if(controls['celular'].errors?.['maxlength']){
                <small class="text-danger mt-1">*Informe um telefone celular válido.</small>
                }
                }
              </div>
            </div>
          </div>


          <div class="col-lg-12">
            <div class="mb-3">
              <label class="form-label">Senha: <span class="text-danger">*</span></label>
              <div class="form-icon position-relative">
                <i-feather name="key" class="fea icon-sm icons"></i-feather>
                <input type="password" class="form-control ps-5" placeholder="Senha" formControlName="password"
                  [ngClass]="formReativo.definindoClasseValidator(formNovaConta, 'password')">
                @if(controls['password'].dirty){
                @if(controls['password'].errors?.['required']){
                <small class="text-danger mt-1">*Campo Obrigatório</small>
                }@else if(controls['password'].errors?.['minLength']){
                <small class="text-danger mt-1">*Mínimo de 8 caracteres.</small>
                }@else if(controls['password'].errors?.['maxLength']){
                <small class="text-danger mt-1">*Máximo 20 caracteres.</small>
                }@else if(controls['password'].errors?.['pattern']){
                <small class="text-danger mt-1">*Sua senha deve conter no mínimo 1 letra minúscula, 1 letra maiúscula
                  e 1 digito numérico.</small>
                }
                }
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="mb-3">
              <label class="form-label">Confirmar Senha: <span class="text-danger">*</span></label>
              <div class="form-icon position-relative">
                <i-feather name="key" class="fea icon-sm icons"></i-feather>
                <input type="password" class="form-control ps-5" placeholder="Confirmar Senha"
                  formControlName="passwordConfirm"
                  [ngClass]="formReativo.definindoClasseValidator(formNovaConta, 'passwordConfirm')">
                @if(controls['passwordConfirm'].dirty){
                @if(controls['passwordConfirm'].errors?.['required']){
                <small class="text-danger mt-1">*Campo Obrigatório</small>
                }@else if(controls['passwordConfirm'].errors?.['minlength']){
                <small class="text-danger mt-1">*Mínimo de 8 caracteres.</small>
                }@else if(controls['passwordConfirm'].errors?.['maxlength']){
                <small class="text-danger mt-1">*Máximo 20 caracteres.</small>
                }@else if(controls['passwordConfirm'].errors?.['pattern']){
                <small class="text-danger mt-1">*Sua senha deve conter no mínimo 1 letra minúscula, 1 letra maiúscula
                  e 1 digito numérico.</small>
                }@else if(controls['passwordConfirm'].errors?.['senhasDivergentes']){
                <small class="text-danger mt-1">*As senhas informadas não são iguais. Verifique e tente
                  novamente</small>
                }
                }
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="form-check">
              <input type="checkbox" name="li-e-concordo" id="li-e-concordo" class="form-check-input"
                [(ngModel)]="isLiEConcordo" [ngModelOptions]="{standalone: true}">
              <label for="li-e-concordo" class="form-check-label">
                Declaro que li e concordo com os <a href="/auth/termos-e-condicoes-de-uso" target="_blank">termos e
                  condições de uso</a>.
              </label>
            </div>
          </div>

          <div class="col-lg-12 mt-2 mb-0">
            <div class="d-grid">
              <button class="btn btn-primary" (click)="criarContaEVerificarEmail()"
                [disabled]="formNovaConta.invalid || formNovaConta.pristine || !isLiEConcordo">
                Criar Conta
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>